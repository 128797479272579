/* eslint-disable no-underscore-dangle */
import React from 'react'

import { Provider } from 'react-redux'

// import { logger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import Loader from 'components/LayoutComponents/Loader'
import { useAuth0 } from './auth0'

import './global.scss'

const history = createBrowserHistory() //createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]


if (process.env.NODE_ENV === 'development' && true) {
  // middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))

// if (process.env.NODE_ENV === 'development' && true) {
//   // middlewares.push(logger)
// }
// const store = createStore(reducers(history),
// compose(applyMiddleware(...middlewares)),
// )

if (process.env.REACT_APP_SURFSIDE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
}

if (process)

  sagaMiddleware.run(sagas, store.dispatch)

function App() {

  const auth0User = useAuth0()

  const { loading } = auth0User

  if (loading) {
    return <Loader />
  }



  return (
    <Provider store={store}>
      <Localization>
        <Router history={history} />
      </Localization>
    </Provider>
  )
}

export { store, history, App }
