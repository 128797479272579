/*eslint-disable */

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'
import { useAuth0 } from './auth0'
import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
// import NotFoundPage from 'pages/404'
import NotFoundPage from 'pages/userManagement/newUser'
import NavBar from './components/SurfsideUIComponents/NavBar'
const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  {
    path: '/home',
    component: loadable(() => import('pages/home')),
  },


  // Create New
  {
    path: '/create/source',
    component: loadable(() => import('pages/create/source')),
  },
  {
    path: '/update/source/:id/:buster',
    component: loadable(() => import('pages/create/source/update')),
  },
  {
    path: '/create/audience',
    component: loadable(() => import('pages/create/audience')),
  },
  {
    path: '/create/destination',
    component: loadable(() => import('pages/create/destination')),
  },

  {
    path: '/apps',
    component: loadable(() => import('pages/credentials')),
  },
  {
    path: '/auth/shopify',
    component: loadable(() => import('pages/auth/Shopify')),
  },
  // {
  //   path: '/auth/shopifyapp',
  //   component: loadable(() => import('pages/auth/ShopifyExt/external')),
  // },
  {
    path: '/auth/callback',
    component: loadable(() => import('pages/auth/ShopifyExt/callback')),
  },
  {
    path: '/auth/hubspot',
    component: loadable(() => import('pages/auth/Hubspot')),
  },
  {
    path: '/auth/facebook',
    component: loadable(() => import('pages/auth/Facebook')),
  },

  //activate

  // {
  //   path: '/activate/placement',
  //   component: loadable(() => import('pages/activate/placement')),
  // },
  // {
  //   path: '/activate/videos',
  //   component: loadable(() => import('pages/activate/videos')),
  // },
  // {
  //   path: '/activate/targeting',
  //   component: loadable(() => import('pages/activate/targeting')),
  // },
  // {
  //   path: '/activate/Rulesets',
  //   component: loadable(() => import('pages/activate/Rulesets')),
  // },
  // {
  //   path: '/activate/Datasets',
  //   component: loadable(() => import('pages/activate/Datasets')),
  // },

  // All Objects

  {
    path: '/all/sources',
    component: loadable(() => import('pages/all/sources')),
  },
  {
    path: '/all/audiences',
    component: loadable(() => import('pages/all/audiences')),
    exact: true,
  },
  {
    path: '/all/destinations',
    component: loadable(() => import('pages/all/destinations')),
    exact: true,
  },

  // Places
  {
    path: '/all/poi',
    component: loadable(() => import('pages/all/poi')),
  },
  {
    path: '/all/retail',
    component: loadable(() => import('pages/all/retail')),
  },
  {
    path: '/all/locations',
    component: loadable(() => import('pages/all/locations')),
  },

  // Edit View
  {
    path: '/all/audiences/:id/edit',
    component: loadable(() => import('pages/edit/audience')),
    exact: true,
  },
  {
    path: '/all/destinations/:id/edit',
    component: loadable(() => import('pages/edit/destination')),
    exact: true,
  },
  {
    path: '/all/update/destination/:accountId/:destinationId',
    component: loadable(() => import('pages/edit/destination')),
  },
  // Detail View
  {
    path: '/detail/sourceType/digital/:id',
    component: loadable(() => import('pages/detail/sourceType/digital')),
  },
  {
    path: '/detail/meta/source/digital/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/digital/meta')),
  },
  {
    path: '/detail/sourceType/physical/:id',
    component: loadable(() => import('pages/detail/sourceType/physical')),
  },
  {
    path: '/detail/sourceType/test/physical/source/:id',
    component: loadable(() => import('pages/detail/sourceType/physical/LocationsDetail')),
  },
  {
    path: '/detail/meta/source/physical/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/physical/meta')),
  },
  {
    path: '/detail/sourceType/advertising/:id',
    component: loadable(() => import('pages/detail/sourceType/advertising')),
  },
  {
    path: '/detail/meta/source/advertising/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/advertising/meta')),
  },
  {
    path: '/detail/sourceType/pos/:id',
    component: loadable(() => import('pages/detail/sourceType/pos')),
  },
  {
    path: '/detail/sourceType/offline/:id',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  {
    path: '/detail/sourceType/pos/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/pos')),
  },
  {
    path: '/detail/meta/source/meta/pos/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  {
    path: '/detail/sourceType/crm/:id',
    component: loadable(() => import('pages/detail/sourceType/crm')),
  },
  {
    path: '/detail/meta/source/meta/crm/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  {
    path: '/detail/audience/:id',
    component: loadable(() => import('pages/detail/audience')),
  },
  {
    path: '/detail/audience-legacy/:id',
    component: loadable(() => import('pages/detail/audience/legacy')),
  },
  {
    path: '/detail/meta/source/thirdparty/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  {
    path: '/detail/meta/source/pos/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/pos')),
  },
  /*
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  }, */
  {
    path: '/detail/meta/source/offline/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  {
    path: '/detail/meta/source/meta/offline/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  /*
  {
    path: '/detail/meta/source/crm/:id/:sourceId',
    component: loadable(() => import('pages/detail/sourceType/thirdparty/meta')),
  },
  },*/
  // Settings
  {
    path: '/accounts/',
    component: loadable(() => import('pages/accounts')),
  },
  {
    path: '/admin/apikeys/',
    component: loadable(() => import('pages/admin/apiKeys')),
  },

  {
    path: '/detail/accounts/:id',
    component: loadable(() => import('pages/detail/accounts')),
  },

  {
    path: '/detail/account/:id/:sourceId',
    component: loadable(() => import('pages/detail/accounts/SourceGroupView')),
  },
  {
    path: '/tags/',
    component: loadable(() => import('pages/tags')),
  },
  {
    path: '/docs/api',
    component: loadable(() => import('pages/api-docs')),
  },
  {
    path: '/metrics/usage',
    component: loadable(() => import('pages/metrics/usage')),
    exact: true
  },
  {
    path: '/metrics/bidder',
    component: loadable(() => import('pages/metrics/bidder')),
    exact: true
  },
  {
    path : '/reasons',
    component: loadable(() => import('pages/reasons')),
    exact: true
  },
  {
    path: '/metrics/details/account/:id',
    component: loadable(() => import('pages/metrics/usage/AccountDetailsPage')),
    exact: true
  },
  // Marketplace
  {
    path: '/marketplace/',
    component: loadable(() => import('pages/marketplace')),
  },

  // Reports
  {
    path: '/reports/',
    component: loadable(() => import('pages/reports')),
  },

  {
    path: '/map/',
    component: loadable(() => import('components/SurfsideUIComponents/MapLocationInput/example')),
  },

  {
    path: '/register',
    component: loadable(() => import('pages/home/Register')),
  },

  // Dashboards
  /*
  {
    path: '/dashboard/alpha',
    component: loadable(() => import('pages/dashboard/alpha')),
  },

  {
    path: '/dashboard/beta',
    component: loadable(() => import('pages/dashboard/beta')),
    exact: true,
  },
  {
    path: '/dashboard/crypto',
    component: loadable(() => import('pages/dashboard/crypto')),
    exact: true,
  },
  {
    path: '/dashboard/gamma',
    component: loadable(() => import('pages/dashboard/gamma')),
    exact: true,
  },
  {
    path: '/dashboard/docs',
    component: loadable(() => import('pages/dashboard/docs')),
    exact: true,
  },
  */

  // Default Pages
  /*
  {
    path: '/pages/login-alpha',
    component: loadable(() => import('pages/pages/login-alpha')),
    exact: true,
  },
  {
    path: '/pages/login-beta',
    component: loadable(() => import('pages/pages/login-beta')),
    exact: true,
  },
  {
    path: '/pages/register',
    component: loadable(() => import('pages/pages/register')),
    exact: true,
  },
  {
    path: '/pages/lockscreen',
    component: loadable(() => import('pages/pages/lockscreen')),
    exact: true,
  },
  {
    path: '/pages/pricing-table',
    component: loadable(() => import('pages/pages/pricing-table')),
    exact: true,
  },
  {
    path: '/pages/invoice',
    component: loadable(() => import('pages/pages/invoice')),
    exact: true,
  },
  */
  //DSP
  {
    path: '/dsp/dashboard',
    component: loadable(() => import('pages/dsp/dashboard')),
    exact: true,
  },
  {
    path: '/dsp/campaigns',
    component: loadable(() => import('pages/dsp/campaigns/campaigns')),
    exact: true,
  },
  {
    path: '/dsp/campaign/edit/:id',
    component: loadable(() => import('pages/dsp/campaigns/edit')),
    exact: true,
  },
  {
    path: '/dsp/campaign/details/:id',
    component: loadable(() => import('pages/dsp/campaigns/campaignDetails')),
    exact: true,
  },
  {
    path: '/dsp/campaigns/:id/lineitems',
    component: loadable(() => import('pages/dsp/lineItems/lineItems')),
    exact: true,
  },
  {
    path: '/dsp/lineitem/view/:id',
    component: loadable(() => import('pages/dsp/lineItems/lineItem')),
    exact: true,
  },
  {
    path: '/dsp/lineitem/edit/:id',
    component: loadable(() => import('pages/dsp/lineItems/edit')),
    exact: true,
  },

  {
    path: '/dsp/placements/new',
    component: loadable(() => import('pages/dsp/placements/new')),
    exact: true,
  },
  {
    path: '/dsp/placements',
    component: loadable(() => import('pages/dsp/placements/placements')),
    exact: true,
  },
  {
    path: '/dsp/placements/:id',
    component: loadable(() => import('pages/dsp/placements/placements')),
    exact: true,
  },
  {
    path: '/dsp/placements/manage/:accountId/:sourceId',
    component: loadable(() => import('pages/dsp/placements/placements')),
    exact: true,
  },
  {
    path: '/dsp/placements/view/:id',
    component: loadable(() => import('pages/dsp/placements/placement')),
    exact: true,
  },
  {
    path: '/dsp/placements/new/wizard',
    component: loadable(() => import('pages/dsp/placements/newWizard')),
    exact: true,
  },
  {
    path: '/dsp/placements/edit/:id/wizard',
    component: loadable(() => import('pages/dsp/placements/editWizard')),
    exact: true,
  },
  {
    path: '/dsp/placements/edit/:id',
    component: loadable(() => import('pages/dsp/placements/new')),
    exact: true,
  },
  {
    path: '/dsp/placements/clone/:id',
    component: loadable(() => import('pages/dsp/placements/new')),
    exact: true,
  },
  {
    path: '/dsp/creative',
    component: loadable(() => import('pages/dsp/creative')),
    exact: true,
  },
  {
    path: '/dsp/banners',
    component: loadable(() => import('pages/dsp/banners/banners')),
    exact: true,
  },
  {
    path: '/dsp/banners/view/:id',
    component: loadable(() => import('pages/dsp/banners/banner')),
    exact: true,
  },
  {
    path: '/dsp/banners/new',
    component: loadable(() => import('pages/dsp/banners/new')),
    exact: true,
  },
  {
    path: '/dsp/banners/edit/:id',
    component: loadable(() => import('pages/dsp/banners/new')),
    exact: true,
  },
  {
    path: '/dsp/banners/clone/:id',
    component: loadable(() => import('pages/dsp/banners/new')),
    exact: true,
  },
  {
    path: '/dsp/videos',
    component: loadable(() => import('pages/dsp/videos/videos')),
    exact: true,
  },
  {
    path: '/dsp/videos/view/:id',
    component: loadable(() => import('pages/dsp/videos/video')),
    exact: true,
  },
  {
    path: '/dsp/videos/new',
    component: loadable(() => import('pages/dsp/videos/new')),
    exact: true,
  },
  {
    path: '/dsp/natives/new',
    component: loadable(() => import('pages/dsp/natives/new')),
    exact: true,
  },
  {
    path: '/dsp/natives/view/:id',
    component: loadable(() => import('pages/dsp/natives/native')),
    exact: true,
  },
  {
    path: '/dsp/natives/edit/:id',
    component: loadable(() => import('pages/dsp/natives/new')),
    exact: true,
  },
  {
    path: '/dsp/natives/clone/:id',
    component: loadable(() => import('pages/dsp/natives/new')),
    exact: true,
  },
  {
    path: '/dsp/videos/edit/:id',
    component: loadable(() => import('pages/dsp/videos/new')),
    exact: true,
  },
  {
    path: '/dsp/videos/clone/:id',
    component: loadable(() => import('pages/dsp/videos/new')),
    exact: true,
  },
  {
    path: '/dsp/targeting',
    component: loadable(() => import('pages/dsp/targeting/targeting')),
    exact: true,
  },
  {
    path: '/dsp/targeting/view/:id',
    component: loadable(() => import('pages/dsp/targeting/target')),
    exact: true,
  },
  {
    path: '/dsp/targeting/clone/:id',
    component: loadable(() => import('pages/dsp/targeting/new')),
    exact: true,
  },
  {
    path: '/dsp/targeting/new',
    component: loadable(() => import('pages/dsp/targeting/new')),
    exact: true,
  },
  {
    path: '/dsp/targeting/edit/:id',
    component: loadable(() => import('pages/dsp/targeting/new')),
    exact: true,
  },
  {
    path: '/dsp/rulesets',
    component: loadable(() => import('pages/dsp/rulesets/rulesets')),
    exact: true,
  },
  {
    path: '/dsp/rulesets/view/:id',
    component: loadable(() => import('pages/dsp/rulesets/ruleset')),
    exact: true,
  },
  {
    path: '/dsp/rulesets/new',
    component: loadable(() => import('pages/dsp/rulesets/new')),
    exact: true,
  },
  {
    path: '/dsp/rulesets/edit/:id',
    component: loadable(() => import('pages/dsp/rulesets/new')),
    exact: true,
  },
  {
    path: '/dsp/rulesets/clone/:id',
    component: loadable(() => import('pages/dsp/rulesets/new')),
    exact: true,
  },

  {
    path: '/dsp/lists',
    component: loadable(() => import('pages/dsp/lists/lists')),
    exact: true,
  },
  // {
  //   path: '/dsp/lists/view/:id',
  //   component: loadable(() => import('pages/dsp/lists/list')),
  //   exact: true,
  // },
  {
    path: '/dsp/lists/clone/:id',
    component: loadable(() => import('pages/dsp/lists/new')),
    exact: true,
  },
  {
    path: '/dsp/lists/new',
    component: loadable(() => import('pages/dsp/lists/new')),
    exact: true,
  },
  {
    path: '/dsp/lists/edit/:id',
    component: loadable(() => import('pages/dsp/lists/new')),
    exact: true,
  },
  {
    path: '/dsp/datasets',
    component: loadable(() => import('pages/dsp/datasets/datasets')),
    exact: true,
  },
  {
    path: '/dsp/datasets/view/:id',
    component: loadable(() => import('pages/dsp/datasets/dataset')),
    exact: true,
  },
  {
    path: '/dsp/datasets/new',
    component: loadable(() => import('pages/dsp/datasets/new')),
    exact: true,
  },
  {
    path: '/dsp/datasets/edit/:id',
    component: loadable(() => import('pages/dsp/datasets/edit')),
    exact: true,
  },
  {
    path: '/dsp/settings',
    component: loadable(() => import('pages/dsp/settings/settings')),
    exact: true,
  },
  {
    path: '/dsp/settings/newuser',
    component: loadable(() => import('pages/dsp/settings/adduser')),
    exact: true,
  },
  {
    path: '/dsp/settings/useredit/:id',
    component: loadable(() => import('pages/dsp/settings/edituser')),
    exact: true,
  },
  {
    path: '/dsp/settings/useredit/:id',
    component: loadable(() => import('pages/dsp/settings/showuser')),
    exact: true,
  },

  // Partner Credentials
  {
    path: '/credentials',
    component: loadable(() => import('pages/credentials')),
    exact: true,
  },
  // Attribution Locations
  {
    path: '/attribution',
    component: loadable(() => import('pages/attribution')),
    exact: true,
  },
  {
    path: '/location/poi',
    component: loadable(() => import('pages/location/poi')),
    exact: true,
  },

  // Compliance
  {
    path: '/compliance/requests',
    component: loadable(() => import('pages/compliance/requests')),
    exact: true,
  },
  {
    path: '/compliance/settings',
    component: loadable(() => import('pages/compliance/settings')),
    exact: true,
  },
  //User Management
  {
    path: '/usermanagement/users',
    component: loadable(() => import('pages/userManagement/users')),
    exact: true,
  },
  {
    path: '/usermanagement/roles',
    component: loadable(() => import('pages/userManagement/roles')),
    exact: true,
  },
  {
    path: '/usermanagement/quotas',
    component: loadable(() => import('pages/userManagement/quotas')),
    exact: true,
  },
  {
    path: '/usermanagement/privileges',
    component: loadable(() => import('pages/userManagement/privileges')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            {routes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
