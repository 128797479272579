import * as React from 'react';
import { dequal as deepEqual } from 'dequal';

function checkDeps(deps) {
    if (!deps || !deps.length) {
       // throw new Error('useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.');
    }
    if (deps.every(isPrimitive)) {
       // throw new Error('useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.');
    }
}
function isPrimitive(val) {
    return  /^[sbn]/.test(typeof val);
}

/**
 * @param value the value to be memoized (usually a dependency list)
 * @returns a memoized version of the value as long as it remains deeply equal
 */
export function useDeepCompareMemoize(value) {
    const ref = React.useRef(value);
    const signalRef = React.useRef(0);
    if (!deepEqual(value, ref.current)) {
        ref.current = value;
        signalRef.current += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useMemo(() => ref.current, [signalRef.current]);
}

export const  useDeepCompareEffect = (callback, dependencies)  =>{
    if (process.env.NODE_ENV !== 'production') {
        checkDeps(dependencies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, useDeepCompareMemoize(dependencies));
}

export function useDeepCompareEffectNoCheck(callback, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return React.useEffect(callback, useDeepCompareMemoize(dependencies));
}
